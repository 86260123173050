<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">设置</div>
    </div>
    <div class="formBox">
      <van-form>
        <van-cell title="账号管理" is-link @click="goAccountManage()" />
        <van-cell title="偏好管理" is-link @click="goManagePage()" />
        <van-cell title="隐私设置" is-link @click="goPrivacyPage()" />
        <div class="back"></div>
        <van-cell title="隐私政策" is-link />
        <van-cell title="服务协议" is-link />
        <van-cell title="关于我们" is-link />
        <van-cell title="客服中心" is-link />
        <div class="back"></div>
        <van-cell title="意见反馈" is-link @click="goFeedbackPage()" />
      </van-form>
    </div>
    <div class="outBtn" @click="outLogin()">退出登录</div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Dialog } from "vant";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({ path: "/myIndex" });
    },
    // 跳转账号管理
    goAccountManage() {
      this.$router.push({ path: "/accountManage" });
    },
    // 跳转偏好管理页面
    goManagePage() {
      this.$router.push({ path: "/managePage" });
    },
    // 跳转隐私设置
    goPrivacyPage() {
      this.$router.push({ path: "/privacyPage" });
    },
    // 意见反馈
    goFeedbackPage() {
      this.$router.push({ path: "/feedbackPage" });
    },
    // 退出登录
    outLogin() {
      Dialog.confirm({
        title: "",
        message: "确定退出登录么",
      })
        .then(() => {
          // on confirm
          Cookies.remove("token");
          this.$router.push({ path: "/login" });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 276px;
}
.formBox {
  margin-top: 32px;
}
.van-cell {
  line-height: 60px;
  font-size: 28px;
  font-weight: 400;
  color: #455669;
}
.back {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.outBtn {
  width: 684px;
  height: 88px;
  background: rgba(250, 40, 25, 0.1);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 88px;
  position: fixed;
  bottom: 32px;
  left: 32px;
}
</style>
